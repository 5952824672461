import { useCallback, useEffect, useState } from "react";
import useDefaultErrorWrapper from "../../../hooks/useDefaultErrorWrapper";
import { useUIContext } from "../../Unknown/UIContext";
import getPatient from "./getPatient";
import { Patient } from "./types";
import { useSupabaseClient } from "../../../common/supabaseClient";

const usePatient = (params: { patientId?: string }) => {
  const { patientId } = params;

  const { setAlert } = useUIContext();

  const supabase = useSupabaseClient();

  const [isLoading, setIsLoading] = useState(false);
  const [patient, setPatient] = useState<Patient | null>(null);

  const { runAsyncFunction } = useDefaultErrorWrapper();

  const refetch = useCallback(async () => {
    if (!patientId || !supabase) return;

    try {
      setIsLoading(true);
      const data = await runAsyncFunction(getPatient, {
        patientId,
        supabase,
      });

      setPatient(data);
    } catch (error) {
      setPatient(null);
      setAlert({
        isShown: true,
        severity: "error",
        message: (error as Error).message,
      });
    } finally {
      setIsLoading(false);
    }
  }, [patientId, runAsyncFunction, setAlert, supabase]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  return { isLoading, patient, refetch };
};

export default usePatient;
