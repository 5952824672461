/* eslint-disable @typescript-eslint/no-explicit-any */
import { Database } from "@Shape-Digital/kudzu-data/lib/types/supabase";
import { SupabaseClient } from "@supabase/supabase-js";
import { supabaseRequest } from "./constants";
import { Appointment } from "./types";

const getAppointment = async (params: {
  supabase: SupabaseClient<Database>;
  appointmentId: string;
}) => {
  const { appointmentId, supabase } = params;

  const { data, error } = await supabase
    .from("appointments")
    .select(supabaseRequest)
    .eq("id", appointmentId)
    .single<Appointment>();

  if (error) {
    throw new Error(error.message);
  }

  return data;
};

export default getAppointment;
