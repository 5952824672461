import { useNavigate } from "react-router-dom";
import { useCallback, useMemo } from "react";
import { useUIContext } from "../../Unknown/UIContext";
import { FormConfig, Patient } from "./types";
import useValidationSchema from "./useValidationSchema";
import useFirebaseAppFunction from "../../../hooks/useFirebaseAppFunction";
import useDefaultErrorWrapper from "../../../hooks/useDefaultErrorWrapper";
import { useFormContext } from "../../Unknown/FormContext";
import useTranslations from "./useTranslations";
import { useAuthContext } from "../../Auth/AuthContextProvider/useAuthContext";
import checkIsAppointmentCheckedIn from "../../../common/checkIsAppointmentCheckedIn";
import { useSupabaseClient } from "../../../common/supabaseClient";

const useFormikComponent = (
  patient: Patient | null,
  appointmentId?: string,
) => {
  const navigate = useNavigate();

  const { setAlert } = useUIContext();

  const { signOut } = useAuthContext();

  const { setFormAlert } = useFormContext();

  const supabase = useSupabaseClient();

  const { patientPrivacyFormSuccessMessage } = useTranslations();

  const provideCheckInAppPatientPrivacyForm = useFirebaseAppFunction(
    "provideCheckInAppPatientPrivacyForm",
  );

  const initialValues = useMemo<FormConfig["initialValues"]>(() => {
    return {
      patientSignature: false,
      patientUnerstand: false,
    };
  }, []);

  const validationSchema = useValidationSchema();
  const { runAsyncFunction } = useDefaultErrorWrapper();

  const onSubmit = useCallback<FormConfig["onSubmit"]>(async () => {
    if (!patient || !appointmentId || !supabase) return;

    try {
      await runAsyncFunction(provideCheckInAppPatientPrivacyForm, {
        patientId: patient.id,
      });

      const isAppointmentCheckedIn = await checkIsAppointmentCheckedIn({
        appointmentId,
        supabase,
      });

      if (isAppointmentCheckedIn) {
        signOut();
        navigate("/checked-in");
        return;
      }

      setFormAlert({
        isShown: true,
        severity: "success",
        message: patientPrivacyFormSuccessMessage,
      });

      navigate("/");
    } catch (error) {
      setAlert({
        isShown: true,
        severity: "error",
        message: (error as Error).message,
      });
    }
  }, [
    patient,
    setAlert,
    navigate,
    provideCheckInAppPatientPrivacyForm,
    runAsyncFunction,
    setFormAlert,
    patientPrivacyFormSuccessMessage,
    signOut,
    appointmentId,
    supabase,
  ]);

  return {
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit,
  };
};

export default useFormikComponent;
