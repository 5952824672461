import { useCallback, useEffect, useState } from "react";
import useDefaultErrorWrapper from "../../../hooks/useDefaultErrorWrapper";
import { useUIContext } from "../../Unknown/UIContext";
import { useSupabaseClient } from "../../../common/supabaseClient";
import getAppointments from "./getAppointment";
import { Appointment } from "./types";

const useAppointment = (params: { appointmentId?: string }) => {
  const { appointmentId } = params;

  const { setAlert } = useUIContext();

  const supabase = useSupabaseClient();

  const [isLoading, setIsLoading] = useState(false);
  const [appointment, setAppointment] = useState<Appointment | null>(null);

  const { runAsyncFunction } = useDefaultErrorWrapper();

  const refetch = useCallback(async () => {
    if (!appointmentId || !supabase) return;

    try {
      setIsLoading(true);
      const data = await runAsyncFunction(getAppointments, {
        appointmentId,
        supabase,
      });

      setAppointment(data);
    } catch (error) {
      setAppointment(null);
      setAlert({
        isShown: true,
        severity: "error",
        message: (error as Error).message,
      });
    } finally {
      setIsLoading(false);
    }
  }, [appointmentId, runAsyncFunction, setAlert, supabase]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  return { isLoading, appointment, refetch };
};

export default useAppointment;
