import { SupabaseClient } from "@supabase/supabase-js";
import { Database } from "@Shape-Digital/kudzu-data/lib/types/supabase";
import { Patient } from "./types";

const supabaseRequest = `
  id,
  first_name,
  last_name,
  city,
  postal_code,
  state,
  country,
  mobile_phone_number,
  email,
  patient_addresses (
      type,
      address
  ),
  patient_forms (
    id,
    type,
    expiration_at
  ),
  legalGuardian: customers (
  id
  )
`;

const getPatient = async (params: {
  supabase: SupabaseClient<Database>;
  patientId: string;
}) => {
  const { patientId, supabase } = params;

  const query = supabase
    .from("patients")
    .select(supabaseRequest)
    .eq("id", patientId)
    .single<Patient>();

  const { data, error } = await query;

  if (error) {
    throw new Error(error.message);
  }

  return data;
};

export default getPatient;
