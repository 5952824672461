import { SUPABASE_TOKEN } from "../common/constants";

const { REACT_APP_SUPABASE_KEY } = process.env;

export const setSupabaseAccessToken = (token: string) => {
  localStorage.setItem(SUPABASE_TOKEN, token);
};

export const getSupabaseAccessToken = () => {
  return localStorage.getItem(SUPABASE_TOKEN);
};

export const initializeSupabaseAccessToken = () => {
  const supabaseToken =
    localStorage.getItem(SUPABASE_TOKEN) || REACT_APP_SUPABASE_KEY || "";

  setSupabaseAccessToken(supabaseToken);
};
