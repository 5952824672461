import { useEffect, useState } from "react";
import { createClient, SupabaseClient } from "@supabase/supabase-js";
import { Database } from "@Shape-Digital/kudzu-data/lib/types/supabase";
import { SUPABASE_TOKEN } from "./constants";

const { REACT_APP_SUPABASE_URL, REACT_APP_SUPABASE_KEY } = process.env;

export const useSupabaseClient = () => {
  const [token, setToken] = useState<string | null>(null);
  const [client, setClient] = useState<SupabaseClient<Database> | null>(null);

  useEffect(() => {
    const updateClient = () => {
      const newToken = localStorage.getItem(SUPABASE_TOKEN);

      setToken(newToken);

      if (!REACT_APP_SUPABASE_URL || !REACT_APP_SUPABASE_KEY) {
        return;
      }

      const headers: { [key: string]: string } = {};
      if (newToken) {
        headers.Authorization = `Bearer ${newToken}`;
      }

      const supabaseClient = createClient(
        REACT_APP_SUPABASE_URL,
        REACT_APP_SUPABASE_KEY,
        {
          global: {
            headers,
          },
        },
      );

      setClient(supabaseClient);
    };

    window.addEventListener("storage", updateClient);

    updateClient();

    return () => {
      window.removeEventListener("storage", updateClient);
    };
  }, []);

  useEffect(() => {
    if (token) {
      const supabaseClient = createClient(
        REACT_APP_SUPABASE_URL || "",
        REACT_APP_SUPABASE_KEY || "",
        {
          global: {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        },
      );

      setClient(supabaseClient);
    }
  }, [token]);

  return client;
};
