import { DatabaseRow } from "@Shape-Digital/kudzu-data/lib/types/common";
import { SupabaseClient } from "@supabase/supabase-js";
import { Database } from "@Shape-Digital/kudzu-data/lib/types/supabase";

const checkIsAppointmentCheckedIn = async ({
  appointmentId,
  supabase,
}: {
  appointmentId: string;
  supabase: SupabaseClient<Database>;
}) => {
  const { data } = await supabase
    .from("appointments")
    .select("id, status")
    .eq("id", appointmentId)
    .single<Pick<DatabaseRow<"appointments">, "id" | "status">>();

  return data && data.status === "checked_in";
};

export default checkIsAppointmentCheckedIn;
