import { Database } from "@Shape-Digital/kudzu-data/lib/types/supabase";
import { SupabaseClient } from "@supabase/supabase-js";
import { ScreeningFormAppointment } from "./types";

export const supabaseRequest = `
  id,
  appointment_patient_details (
    id,
    first_name,
    last_name,
    date_birth,
    patient:patient_id (
      mrn
    )
  )
`;

const getAppointment = async (params: {
  supabase: SupabaseClient<Database>;
  appointmentId: string;
}) => {
  const { supabase, appointmentId } = params;

  const query = supabase
    .from("appointments")
    .select(supabaseRequest)
    .eq("id", appointmentId)
    .single<ScreeningFormAppointment>();

  const { data, error } = await query;

  if (error) {
    throw new Error(error.message);
  }

  return data;
};

export default getAppointment;
